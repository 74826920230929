@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .main-gallery {
    height: calc(100vh - 134px);
  }

  .fill-available {
    width: -webkit-fill-available;
    width: -moz-available;
  }
}

@font-face {
  font-family: 'Inter';
  src: url('/css/fonts/inter.ttf') format('truetype');
}

.tribute-container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 999999;
}
.tribute-container ul {
  margin: 0;
  margin-top: 2px;
  padding: 0;
  list-style: none;
  background: #efefef;
}
.tribute-container li {
  padding: 5px 5px;
  cursor: pointer;
}
.tribute-container li.highlight {
  background: #ddd;
}
.tribute-container li span {
  font-weight: bold;
}
.tribute-container li.no-match {
  cursor: default;
}
.tribute-container .menu-highlighted {
  font-weight: bold;
}
